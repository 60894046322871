import './style.css'
import * as THREE from 'three'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'


/**
 * Cursor
 */
const cursor = {
    x: 0,
    y: 0
}
window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = - (event.clientY / sizes.height - 0.5)
})


/**
 * Assign div
 */
const container = document.getElementById('container')


/**
 * Scene
 */
const scene = new THREE.Scene()


/**
 * Sizes
 */
const sizes = {
    width: container.offsetWidth,
    height: container.offsetHeight
}


/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight(0x00ffffff, 1.5)
directionalLight.position.set(3, 1, 4)
directionalLight.rotation.y = Math.PI / 2
//directionalLight.castShadow = true
directionalLight.shadow.camera.far = 10
scene.add(directionalLight)


/**
 * Loaders
 */
const gltfLoader = new GLTFLoader()
const cubeTextureLoader = new THREE.CubeTextureLoader()


/**
 * Update all materials (Add environment map to all materials)
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) => 
    {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            child.material.envMap = environmentMap
            child.material.envMapIntensity = 1
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}


/**
  * Environment map
  */
const environmentMap = cubeTextureLoader.load([
    
    // '/env_map/px.jpg',
    // '/env_map/px.jpg',
    // '/env_map/px.jpg',
    // '/env_map/px.jpg',
    // '/env_map/px.jpg',
    // '/env_map/px.jpg'

    
    'https://uploads-ssl.webflow.com/6278e7673c3926950d4807d7/628799e75d0be5341b310405_px.jpg',
    'https://uploads-ssl.webflow.com/6278e7673c3926950d4807d7/628799e75d0be5341b310405_px.jpg',
    'https://uploads-ssl.webflow.com/6278e7673c3926950d4807d7/628799e75d0be5341b310405_px.jpg',
    'https://uploads-ssl.webflow.com/6278e7673c3926950d4807d7/628799e75d0be5341b310405_px.jpg',
    'https://uploads-ssl.webflow.com/6278e7673c3926950d4807d7/628799e75d0be5341b310405_px.jpg',
    'https://uploads-ssl.webflow.com/6278e7673c3926950d4807d7/628799e75d0be5341b310405_px.jpg'
])


/**
 * Models
 */
var modelOne

gltfLoader.load(
    //'/models/GearClear.glb',
    'https://uploads-ssl.webflow.com/6278e7673c3926950d4807d7/62890a0f8b652b98a75b1930_GearClear.glb.txt',
    (gearClear) =>
    {
            modelOne = gearClear.scene
            modelOne.position.z = .08
            modelOne.rotation. y = .6
            
            scene.add(modelOne)

        /// Calls 'updated all materials'
        updateAllMaterials()
    }   
)


var modelTwo

gltfLoader.load(
    //'/models/GearRust.glb',
    'https://uploads-ssl.webflow.com/6278e7673c3926950d4807d7/62890a1382360f0b6e16806d_GearRust.glb.txt',
    (gearRust) =>
    {
        modelTwo = gearRust.scene
        modelTwo.position.x = 3
        modelTwo.position.z = -5
        modelTwo.rotation. y = .6

        scene.add(modelTwo)
       
//         /// Calls 'updated all materials'
        updateAllMaterials()
        
    }   
)


/**
 * Camera
 */
 const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height)
//camera.position.x = -2
//camera.position.y = 0
camera.position.z = 6
 scene.add(camera)

 camera.lookAt(scene.position)


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    container: container, alpha: true
})
container.appendChild(renderer.domElement)
renderer.setSize (sizes.width, sizes.height)
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFShadowMap
renderer.physicallyCorrectLights = true


/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{

const elapsedTime = clock.getElapsedTime ()

//Update camera
camera.position.x = cursor.x 
camera.position.y = cursor.y 
camera.lookAt(scene.position)




//Update objects
if (modelOne) modelOne.rotation.z += 0.003
if (modelTwo) modelTwo.rotation.z += -0.003

    
// Render
renderer.render(scene, camera)

// Call tick again on the next frame
window.requestAnimationFrame(tick)
}

tick()